var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Toast'),_c('Card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Servidores")]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"flex mb-3"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onFilter()}}},[_c('div',{staticClass:"p-inputgroup"},[_c('InputText',{attrs:{"autofocus":"","type":"search","allowEmpty":true,"useGrouping":false,"placeholder":"CPF, Matrícula ou E-mail"},model:{value:(_vm.filters.matriculaCpf.value),callback:function ($$v) {_vm.$set(_vm.filters.matriculaCpf, "value", $$v)},expression:"filters.matriculaCpf.value"}}),_c('Button',{staticClass:"p-button",on:{"click":function($event){return _vm.onFilter()}}},[(!_vm.loading)?_c('span',{staticClass:"pi pi-search"}):_vm._e(),(_vm.loading)?_c('span',{staticClass:"pi pi-spin pi-spinner"}):_vm._e(),_c('span',{staticClass:"ml-2"},[_vm._v("Buscar")])])],1)]),_c('Button',{staticClass:"ml-2 pr-3 p-button-secondary",attrs:{"icon":"pi pi-filter-slash","label":"Limpar"},on:{"click":_vm.clearFilters}})],1),_c('DataTable',{directives:[{name:"show",rawName:"v-show",value:(_vm.exibeTabela),expression:"exibeTabela"}],attrs:{"value":_vm.servidores,"stripedRows":"","paginator":true,"rows":10,"loading":_vm.loading,"totalRecords":_vm.totalRecords,"paginatorTemplate":"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown","rowsPerPageOptions":_vm.rowsPerPageOptions,"currentPageReportTemplate":"Exibindo {first} a {last} de {totalRecords} servidores","responsiveLayout":"scroll"},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(" Nenhum servidor encontrado. ")]},proxy:true},{key:"loading",fn:function(){return [_vm._v(" Carregando. Por favor aguarde. ")]},proxy:true}])},[_c('Column',{attrs:{"field":"cpf","header":"CPF"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.hasConsentimentoConsignataria ? data.cpf : '-')+" ")]}}])}),_c('Column',{attrs:{"field":"nome","header":"Nome"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.hasConsentimentoConsignataria ? data.nome : '-')+" ")]}}])}),_c('Column',{attrs:{"field":"matricula","header":"Matrícula"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.hasConsentimentoConsignataria ? data.matricula : '-')+" ")]}}])}),_c('Column',{attrs:{"field":"entidadeNome","header":"Entidade"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.hasConsentimentoConsignataria ? data.entidadeNome : '-')+" ")]}}])}),_c('Column',{attrs:{"field":"vinculoNome","header":"Vínculo"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.hasConsentimentoConsignataria ? data.vinculoNome : '-')+" ")]}}])}),_c('Column',{attrs:{"header":"Situação"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [(data.hasConsentimentoConsignataria)?_c('span',[_c('Tag',{attrs:{"value":data.validacao.value,"severity":data.validacao.severity,"icon":data.validacao.icon}})],1):_c('span',[_c('h3',[_vm._v("Usuário não consentiu acesso dos dados a consignatária.")])])]}}])}),_c('Column',{attrs:{"header":"Ações"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [(data.hasConsentimentoConsignataria)?_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Detalhes'),expression:"'Detalhes'",modifiers:{"left":true}}],staticClass:"p-button-rounded p-button-success mr-2",on:{"click":function($event){return _vm.detalheServidor(data)}}},[(!_vm.loadingDetail)?_c('span',{staticClass:"pi pi-eye"}):_vm._e(),(_vm.loadingDetail)?_c('span',{staticClass:"pi pi-spin pi-spinner"}):_vm._e()]):_vm._e(),(!data.hasConsentimentoConsignataria)?_c('Button',{staticClass:"p-button-rounded p-button-danger mr-2",attrs:{"icon":"pi pi-shield"}}):_vm._e()]}}])})],1),_c('Tag',{directives:[{name:"show",rawName:"v-show",value:(_vm.exibeTabelaPendente),expression:"exibeTabelaPendente"}],style:({ width: '100%', fontSize: '18px' }),attrs:{"severity":"warning","value":"Há servidores com portabilidade pendente!"}}),_c('DataTable',{directives:[{name:"show",rawName:"v-show",value:(_vm.exibeTabelaPendente),expression:"exibeTabelaPendente"}],attrs:{"value":_vm.servidoresPendentes,"stripedRows":"","paginator":true,"rows":10,"loading":_vm.loading,"totalRecords":_vm.totalRecords,"paginatorTemplate":"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown","rowsPerPageOptions":_vm.rowsPerPageOptions,"currentPageReportTemplate":"Exibindo {first} a {last} de {totalRecords} servidores","responsiveLayout":"scroll"},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(" Nenhum servidor encontrado. ")]},proxy:true},{key:"loading",fn:function(){return [_vm._v(" Carregando. Por favor aguarde. ")]},proxy:true}])},[_c('Column',{attrs:{"header":"Cadastrado Em"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm._f("formatarData")(data.proposta.auditMetadata.cadastradoEm))+" ")]}}])}),_c('Column',{attrs:{"field":"proposta.matricula.servidor.cpf","header":"CPF"}}),_c('Column',{attrs:{"field":"proposta.matricula.matricula","header":"Matrícula"}}),_c('Column',{attrs:{"field":"proposta.matricula.servidor.nome","header":"Nome"}}),_c('Column',{attrs:{"field":"proposta.matricula.entidade.nome","header":"Entidade"}}),_c('Column',{attrs:{"sortable":true,"field":"proposta.statusProposta.nome","header":"Situação"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [(data.proposta.statusProposta.nome === 'Pendente')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip pendente",attrs:{"label":"Pendente"}}):_vm._e()]}}])})],1)]},proxy:true}])}),_c('Dialog',{style:({ width: '350px' }),attrs:{"modal":"","header":"Header","visible":_vm.deleteServidorDialog},on:{"update:visible":function($event){_vm.deleteServidorDialog=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h3',[_vm._v("Excluir Servidor")])]},proxy:true},{key:"footer",fn:function(){return [_c('Button',{staticClass:"p-button-text",attrs:{"label":"Não","icon":"pi pi-times"},on:{"click":function($event){_vm.deleteServidorDialog = false}}}),_c('Button',{attrs:{"label":"Sim","icon":"pi pi-check","autofocus":""},on:{"click":_vm.deleteServidor}})]},proxy:true}])},[_vm._v(" Confirma a exclusão de "+_vm._s(_vm.servidor.nome)+" ? ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }