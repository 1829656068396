import { render, staticRenderFns } from "./ServidorIndex.vue?vue&type=template&id=3627bde2&scoped=true"
import script from "./ServidorIndex.vue?vue&type=script&lang=js"
export * from "./ServidorIndex.vue?vue&type=script&lang=js"
import style0 from "./ServidorIndex.vue?vue&type=style&index=0&id=3627bde2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3627bde2",
  null
  
)

export default component.exports