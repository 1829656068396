<template>
  <div>
    <Toast />
    <Card>
      <template #title>Servidores</template>
      <template #content>
        <div class="flex mb-3">
          <form @submit.prevent="onFilter()">
            <div class="p-inputgroup">
              <InputText
                v-model="filters.matriculaCpf.value"
                autofocus
                type="search"
                :allowEmpty="true"
                :useGrouping="false"
                placeholder="CPF, Matrícula ou E-mail" />
              <Button class="p-button" @click="onFilter()">
                <span v-if="!loading" class="pi pi-search"></span>
                <span v-if="loading" class="pi pi-spin pi-spinner"></span>
                <span class="ml-2">Buscar</span>
              </Button>
            </div>
          </form>
          <Button
            class="ml-2 pr-3 p-button-secondary"
            icon="pi pi-filter-slash"
            label="Limpar"
            @click="clearFilters" />
        </div>
        <DataTable
          v-show="exibeTabela"
          :value="servidores"
          stripedRows
          :paginator="true"
          :rows="10"
          :loading="loading"
          :totalRecords="totalRecords"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="rowsPerPageOptions"
          currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} servidores"
          responsiveLayout="scroll">
          <template #empty> Nenhum servidor encontrado. </template>
          <template #loading> Carregando. Por favor aguarde. </template>
          <Column field="cpf" header="CPF">
            <template #body="{ data }">
              {{ data.hasConsentimentoConsignataria ? data.cpf : '-' }}
            </template>
          </Column>
          <Column field="nome" header="Nome">
            <template #body="{ data }">
              {{ data.hasConsentimentoConsignataria ? data.nome : '-' }}
            </template>
          </Column>
          <Column field="matricula" header="Matrícula">
            <template #body="{ data }">
              {{ data.hasConsentimentoConsignataria ? data.matricula : '-' }}
            </template>
          </Column>
          <Column field="entidadeNome" header="Entidade">
            <template #body="{ data }">
              {{ data.hasConsentimentoConsignataria ? data.entidadeNome : '-' }}
            </template>
          </Column>
          <Column field="vinculoNome" header="Vínculo">
            <template #body="{ data }">
              {{ data.hasConsentimentoConsignataria ? data.vinculoNome : '-' }}
            </template>
          </Column>
          <Column header="Situação">
            <template #body="{ data }">
              <span v-if="data.hasConsentimentoConsignataria">
                <Tag
                  :value="data.validacao.value"
                  :severity="data.validacao.severity"
                  :icon="data.validacao.icon"></Tag>
              </span>

              <span v-else>
                <h3>Usuário não consentiu acesso dos dados a consignatária.</h3>
              </span>
            </template>
          </Column>
          <Column header="Ações">
            <template #body="{ data }">
              <Button
                v-if="data.hasConsentimentoConsignataria"
                v-tooltip.left="'Detalhes'"
                class="p-button-rounded p-button-success mr-2"
                @click="detalheServidor(data)">
                <span v-if="!loadingDetail" class="pi pi-eye"></span>
                <span v-if="loadingDetail" class="pi pi-spin pi-spinner"></span>
              </Button>
              <Button
                v-if="!data.hasConsentimentoConsignataria"
                icon="pi pi-shield"
                class="p-button-rounded p-button-danger mr-2" />

              <!-- <Button
                v-if="!data.hasVinculoConsignataria"
                v-tooltip.left="'Consignatária não opera com este vínculo'"
                icon="pi pi-info"
                class="p-button-rounded p-button-info mr-2" /> -->
            </template>
          </Column>
        </DataTable>
        <Tag
          v-show="exibeTabelaPendente"
          severity="warning"
          value="Há servidores com portabilidade pendente!"
          :style="{ width: '100%', fontSize: '18px' }"></Tag>
        <DataTable
          v-show="exibeTabelaPendente"
          :value="servidoresPendentes"
          stripedRows
          :paginator="true"
          :rows="10"
          :loading="loading"
          :totalRecords="totalRecords"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="rowsPerPageOptions"
          currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} servidores"
          responsiveLayout="scroll">
          <template #empty> Nenhum servidor encontrado. </template>
          <template #loading> Carregando. Por favor aguarde. </template>
          <Column header="Cadastrado Em">
            <template #body="{ data }">
              {{ data.proposta.auditMetadata.cadastradoEm | formatarData }}
            </template>
          </Column>
          <Column field="proposta.matricula.servidor.cpf" header="CPF"></Column>
          <Column
            field="proposta.matricula.matricula"
            header="Matrícula"></Column>
          <Column
            field="proposta.matricula.servidor.nome"
            header="Nome"></Column>
          <Column
            field="proposta.matricula.entidade.nome"
            header="Entidade"></Column>
          <Column
            :sortable="true"
            field="proposta.statusProposta.nome"
            header="Situação">
            <template #body="{ data }">
              <Chip
                v-if="data.proposta.statusProposta.nome === 'Pendente'"
                label="Pendente"
                class="mr-2 mb-2 custom-chip pendente" />
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>
    <Dialog
      modal
      header="Header"
      :style="{ width: '350px' }"
      :visible.sync="deleteServidorDialog">
      <template #header>
        <h3>Excluir Servidor</h3>
      </template>

      Confirma a exclusão de {{ servidor.nome }} ?

      <template #footer>
        <Button
          label="Não"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteServidorDialog = false" />
        <Button
          label="Sim"
          icon="pi pi-check"
          autofocus
          @click="deleteServidor" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import moment from 'moment'
import { watch } from 'vue-demi'
import MatriculaService from '@/service/MatriculaService.js'
import { consignatariaStore } from '@/stores/consignataria'
import CompraDividaService from '@/service/CompraDividaService'

export default {
  setup() {
    const store = consignatariaStore()
    return { store }
  },

  data() {
    return {
      servidores: null,
      servidor: {},
      servidoresPendentes: [],
      deleteServidorDialog: false,
      exibeTabela: false,
      loading: false,
      totalRecords: 0,
      parametros: {},
      filters: {
        matriculaCpf: { value: null, matchMode: 'exactly' },
      },
      loadingDetail: false,
      exibeTabelaPendente: false,
    }
  },

  computed: {
    rowsPerPageOptions() {
      if (this.totalRecords < 5) {
        return null
      }
      return [5, 10, 25]
    },
  },

  created() {
    this.matriculaService = new MatriculaService(this.$http)
    this.compraDividaService = new CompraDividaService(this.$http)
    watch(
      () => this.store.consignatariaSelecionada,
      () => {
        this.clearFilters()
        this.carregarServidoresPendentes()
      },
      { deep: true },
    )
  },

  mounted() {
    this.parametros = {
      first: 0,
      page: 0,
      filters: this.filters,
      consignatariaSelecionada: 0,
    }
    this.carregarServidoresPendentes()
  },

  methods: {
    detalheServidor(data) {
      this.loadingDetail = true
      this.$router.push({ name: 'servidores-margem', params: { id: data.id } })
      this.loadingDetail = false
    },

    onPage(event) {
      this.parametros = event
      this.carregarDadosFiltrados()
    },

    onFilter() {
      if (!this.store.consignatariaSelecionada) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Selecione a consignatária.',
          life: 10000,
        })
        return
      }

      if (this.filters.matriculaCpf.value) {
        this.parametros.filters = this.filters
        this.carregarDadosFiltrados()
      } else {
        this.$toast.add({
          severity: 'warn',
          summary: 'Informe o CPF ou a matrícula.',
          life: 10000,
        })
      }
    },

    carregarDadosFiltrados() {
      this.parametros.consignatariaSelecionada =
        this.store.consignatariaSelecionada.id
      this.loading = true
      this.matriculaService
        .getMatriculasPelaConsignataria(this.parametros)
        .then((res) => {
          this.servidores = res
          this.totalRecords = res.length
          this.loading = false
          this.exibeTabela = true
        })
        .catch((err) => {
          this.loading = false
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        })
    },

    carregarServidoresPendentes() {
      if (this.store.consignatariaSelecionada) {
        this.loading = true
        this.parametros.consignatariaSelecionada =
          this.store.consignatariaSelecionada.id
        this.compraDividaService
          .getComprasDividasPorConsignatariaVendidoPendente(
            this.parametros.consignatariaSelecionada,
          )
          .then((res) => {
            this.servidoresPendentes = res
            this.totalRecords = res.length
            this.loading = false
            this.exibeTabelaPendente = this.servidoresPendentes.length > 0
          })
          .catch((err) => {
            this.loading = false
            this.$toast.add({
              severity: 'error',
              summary: err.response.data.message,
              life: 10000,
            })
          })
      }
    },

    clearFilters() {
      this.filters.matriculaCpf.value = null
      this.servidores = null
      this.exibeTabela = false
    },

    formatarData(value) {
      if (value == null || typeof value === 'undefined' || value == '') {
        return '-'
      } else {
        this.data = moment(value).format('DD/MM/YYYY')
        return this.data
      }
    },
  },
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.pendente {
  color: #1f2d3d;
  background-color: #ffc107;
  font-weight: bold;
}
</style>
